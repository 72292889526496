//colors
$pastel-red: #ff6b6b;
$carmine-pink: #e86262;
$global-purple: #c678dd;
$variable-red: #e1686e;
$dark-variable-red: #a84e52;
$string-green: #92c178;
$dark-string-green: #6d915b;
$pastel-blue: #4ba3c3;
$dark-pastel-blue: #3e86a0;
$teal-blue: #37778e;
$constant-yellow: #e1bf7c;
$dark-constant-yellow: #b99d66;
$pumpkin: #ff6629;

$white: #ffffff;
$light-gray: #c5c6d0;
$gray: #a5a0b1;
$dark-gray: #62606d;
$darker-gray: #2b3037;
$back-button-gray: #21252a;
$darkest-gray: #1b1b1f;

$action-button-background: #282c32;

$red: #991a30;
$dark-red: #480e14;
$blue: #3a4b69;
$dark-blue: #1d2838;
$yellow: #feb650;
$dark-yellow: #bf7023;
$green: #00766f;
$dark-green: #113735;
$pink: #ffb6bd;
$dark-pink: #ad6568;

//special button colors
$transparent-dark-gray: rgba(27, 27, 27, 0.5);
$transparent-gray: rgba(165, 160, 177, 0.5);

//sizes
$pageWidth: 1500px;
