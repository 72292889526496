.Definition {
  width: fit-content;
  .word-and-speech {
    .word {
      display: inline-block;
      margin-right: 10px;
      font-size: 24px;
      font-style: italic;
    }
  }

  .description {
    display: inline-block;
    color: $light-gray;
    padding: 10px 10px 0px 10px;
    font-size: 16px;
    width: 800px;
    div {
      display: inline-block;
    }
  }

  .tab {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .WordLink {
    font-size: 16px;
  }
}
