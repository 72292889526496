@charset 'UTF-8';

@font-face {
  font-family: 'Droid Sans Mono';
  font-style: normal;
  font-weight: 900;
  src: local('Droid Sans Mono'),
    url(../fonts/DroidSansMono-webfont.woff) format('woff'),
    url(../fonts/DroidSansMono-webfont.ttf) format('ttf'),
    url(../fonts/DroidSansMono-webfont.svg) format('svg'),
    url(../fonts/DroidSansMono-webfont.eot) format('eot');
}

@import './variables';

// components
@import './components/wordLink';
@import './components/definition';
@import './components/discussion';
@import './components/media';

// pages
@import './pages/base';
@import './pages/home';
@import './pages/tagPage';

.heart {
  color: $variable-red;
}
